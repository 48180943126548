import React, { Fragment, useContext, useEffect, useState } from 'react'
import { PageProps } from 'gatsby'
import nl2br from 'react-nl2br'
import { scroller } from 'react-scroll'
import {
  Head,
  Hero,
  MosaicSection,
  ImageSection,
  Button,
  Form,
  Lightbox,
  Footer,
  Map,
  Tabs,
  Video,
  Modal,
  WhatsApp,
} from '~/components'
import { getVideoId } from '~/components/Video'
import { Image } from '~/components/Lightbox'
import { context } from '~/layouts'
import { Lazyload as Imagee } from '~/components'
import { useMediaQuery } from 'react-responsive'
import Slider, { ArrowType } from '@fil1pe/react-slider'
import { useApi } from '~/siteApi'
import cn from 'classnames'
import * as st from '~/assets/styl/EnterpriseDetails.module.styl'
import * as st1 from '~/assets/styl/Contact.module.styl'
import * as st2 from '~/assets/styl/Team.module.styl'

import commercialRoomShowcase from '~/assets/img/onne-home-1-showcase.jpg'
import commercialRoom1 from '~/assets/img/onne-home-2.jpg'
import commercialRoom2 from '~/assets/img/onne-home-1.jpg'
import commercialRoom3 from '~/assets/img/onne-home-3.jpg'
import commercialRoom4 from '~/assets/img/onne-home-4.jpg'
import commercialRoom5 from '~/assets/img/onne-home-5.jpg'

function menuBarHeight() {
  return -document.getElementById('menubar').offsetHeight
}

const DatasheetModal = ({
  title,
  children,
  open,
  onClose,
}: {
  title: string
  children: React.ReactNode
  open: boolean
  onClose: () => void
}) => (
  <Modal open={open} onClose={onClose} className={st2.modal}>
    {(closeProps) => (
      <div className={st2.content}>
        <h3>{title}</h3>
        {children}
        <button {...closeProps} className={st2.close}></button>
      </div>
    )}
  </Modal>
)

interface Context {
  enterprise: {
    imageFeature: string
    imageLogo: string
    imageTop: string
    imageAbout: string
    imageVideo: string
    stages: Array<{
      name: string
      value: string
    }>
    videos: Array<{
      name: string
      url: string
    }>
    name: string
    tag: string
    room: string
    metreage: string
    garage: string
    aboutTitle: string
    aboutContent: string
    videoContent: string
    videoUrl: string
    videoCta: string
    plantTitle: string
    workTitle: string
    workContent: string
    imageLocation: string
    address: string
    neighborhood: string
    city: string
    state: string
    latitude: string
    longitude: string
    formTitle: string
    formContent: string
    galleries: Array<{
      gallery: string
      title: string
      description: string
      cta: string
      datasheet: Array<string>
      images?: Array<Image>
      types?: Array<{
        title: string
        images: Array<Image>
      }>
    }>
    plants: Array<{
      name: string
      images: Array<{
        legend: string
        title: string
        urls: {
          original: string
          large: string
        }
      }>
    }>
    works: Array<{
      title: string
      images: Array<{
        legend: string
        urls: {
          original: string
          large: string
        }
      }>
    }>
    slug: string
  }
}

const EnterprisePage = ({
  location,
  pageContext: _pageContext,
  slug: _slug,
}: PageProps & { slug?: string }) => {
  const pageContext = _pageContext as Context
  const slug = pageContext?.enterprise?.slug || _slug
  const { enterprise }: Context = useApi(pageContext, 'page-enterprise/' + slug)

  const plants = (enterprise?.plants || []).filter(
    ({ images }) => images.length
  )

  const { setLoading } = useContext(context)
  useEffect(() => {
    setLoading(!enterprise?.name)
  }, [enterprise])

  const [modal, setModal] = useState(-1)
  const [zoomIndex, setZoomIndex] = useState(0)
  const [zoomImages, setZoomImages] = useState<Array<Image>>()

  function Zoom(index: number, images: Array<Image>) {
    setZoomIndex(index)
    setZoomImages(images)
  }

  const Stage = (name: string, value: number) => (
    <div className={value === 0 ? st.light : null}>
      <span>{value}%</span>
      <div>
        <span>{name}</span>
        <div style={{ width: `${value}%` }} className={st.bar}></div>
      </div>
    </div>
  )

  const small = useMediaQuery({
    query: '(min-width: 501px)',
  })
  const medium = useMediaQuery({
    query: '(min-width: 851px)',
  })
  const large = useMediaQuery({
    query: '(min-width: 1101px)',
  })

  const [type, setType] = useState({})

  const imagesCommercialRoom = [
    {
      legend: 'À 500m da Praia Central',
      urls: {
        original: commercialRoom1,
        large: commercialRoomShowcase,
      },
    },
    {
      legend: '',
      urls: {
        original: commercialRoom2,
        large: commercialRoom2,
      },
    },
    {
      legend: '',
      urls: {
        original: commercialRoom3,
        large: commercialRoom3,
      },
    },
    {
      legend: '',
      urls: {
        original: commercialRoom4,
        large: commercialRoom4,
      },
    },
    {
      legend: '',
      urls: {
        original: commercialRoom5,
        large: commercialRoom5,
      },
    },
  ]

  const specificVideo = enterprise?.videos?.find(
    (video) =>
      video.name === 'ONNe Home Residence - Salas Comerciais para Locação'
  )

  return !enterprise ? (
    <></>
  ) : (
    <>
      <Head
        location={location}
        title={enterprise.name + ' - Aikon Empreendimentos'}
      />
      <Hero
        image={enterprise.imageTop}
        title={nl2br(enterprise.name)}
        subtitle="Conheça o empreendimento"
        tag={enterprise.tag}
        location={`${enterprise.neighborhood} - ${enterprise.city} - ${enterprise.state}`}
      />
      <section className={st.intro}>
        <div className={st.container}>
          <div>
            <img
              src={enterprise.imageFeature}
              width="680"
              height="900"
              className={st.image}
            />
            <div className={st.logo}>
              <img src={enterprise.imageLogo} width="360" height="360" />
              <Button
                onClick={() =>
                  scroller.scrollTo('saiba-mais', {
                    smooth: true,
                    duration: 500,
                    offset: large ? 0 : menuBarHeight(),
                  })
                }
              >
                Saiba mais
              </Button>
            </div>
          </div>

          {Boolean(
            enterprise.room || enterprise.metreage || enterprise.garage
          ) && (
            <ul className={st.elements}>
              {enterprise.room && (
                <li>
                  <span className={st.bed}></span>
                  {enterprise.room}
                </li>
              )}
              {enterprise.metreage && (
                <li>
                  <span className={st.area}></span>
                  {enterprise.metreage}
                </li>
              )}
              {enterprise.garage && (
                <li>
                  <span className={st.car}></span>
                  {enterprise.garage}
                </li>
              )}
            </ul>
          )}

          {enterprise.aboutTitle && (
            <div className={st.beachfront}>
              <div>
                <h3>{nl2br(enterprise.aboutTitle)}</h3>
                {enterprise.aboutContent && (
                  <p>{nl2br(enterprise.aboutContent)}</p>
                )}
                <div className={st.links}>
                  {Boolean(enterprise.plantTitle && plants.length) && (
                    <button
                      onClick={() =>
                        scroller.scrollTo('plantas', {
                          smooth: true,
                          duration: 500,
                          offset: large ? 0 : menuBarHeight(),
                        })
                      }
                    >
                      Plantas
                    </button>
                  )}
                  {Boolean(enterprise.latitude && enterprise.longitude) && (
                    <button
                      onClick={() =>
                        scroller.scrollTo('localizacao', {
                          smooth: true,
                          duration: 500,
                          offset: large ? 0 : menuBarHeight(),
                        })
                      }
                    >
                      Localização
                    </button>
                  )}
                  <Button
                    onClick={() =>
                      scroller.scrollTo('saiba-mais', {
                        smooth: true,
                        duration: 500,
                        offset: large ? 0 : menuBarHeight(),
                      })
                    }
                  >
                    Saiba mais
                  </Button>
                </div>
              </div>
              {enterprise.imageAbout && (
                <img src={enterprise.imageAbout} width="466" height="639" />
              )}
            </div>
          )}
        </div>
      </section>
      <div className={st.videoWrapper}>
        {Boolean(medium && enterprise.videoUrl) && (
          <div className={st.video}>
            <Video
              title={enterprise.videoCta}
              thumbnail={
                enterprise.imageVideo ||
                `https://img.youtube.com/vi/${getVideoId(
                  enterprise.videoUrl
                )}/0.jpg`
              }
              src={enterprise.videoUrl}
            />
            <div>
              {enterprise.videoContent && (
                <p>{nl2br(enterprise.videoContent)}</p>
              )}
              {enterprise.formTitle && (
                <Button
                  onClick={() =>
                    scroller.scrollTo('saiba-mais', {
                      smooth: true,
                      duration: 500,
                      offset: large ? 0 : menuBarHeight(),
                    })
                  }
                >
                  Solicite informações
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
      {Boolean(!medium && enterprise.videoUrl) && (
        <MosaicSection
          title={enterprise.videoCta}
          description={enterprise.videoContent}
          buttonLabel={enterprise.formTitle ? 'Solicite informações' : null}
          onClick={() =>
            scroller.scrollTo('saiba-mais', {
              smooth: true,
              duration: 500,
              offset: large ? 0 : menuBarHeight(),
            })
          }
        >
          <Video
            title={enterprise.videoCta}
            thumbnail={
              enterprise.imageVideo ||
              `https://img.youtube.com/vi/${getVideoId(
                enterprise.videoUrl
              )}/0.jpg`
            }
            src={enterprise.videoUrl}
            className={st.videoMosaic}
          />
        </MosaicSection>
      )}
      {enterprise.galleries.map(
        (
          { title, description, cta, datasheet, gallery, images, types },
          key
        ) => (
          <Fragment key={key}>
            {gallery === 'Ficha técnica' && (
              <DatasheetModal
                title={gallery}
                open={modal === key}
                onClose={() => setModal(-1)}
              >
                <ul>
                  {datasheet.map((data, key) => (
                    <li key={key}>{data}</li>
                  ))}
                </ul>
              </DatasheetModal>
            )}
            <MosaicSection
              title={title}
              description={
                <>
                  {description}
                  {Boolean(types?.length) && (
                    <>
                      <br />
                      <br />
                      <span className={st.obs}>
                        Escolha o tipo de apartamento que deseja visualizar:
                      </span>
                    </>
                  )}
                </>
              }
              buttonLabel={
                gallery === 'Ficha técnica' ? cta || 'Ficha técnica' : cta
              }
              knowMore
              buttonOutlined={images?.length ? false : true}
              onClick={gallery === 'Ficha técnica' ? () => setModal(key) : null}
              images={type[key] ? types[type[key]].images : images}
              zoom={images?.length ? Zoom : null}
              reverse={!images?.length || gallery.includes('esquerda')}
              style={{ overflow: 'hidden' }}
              first={key === 0}
              last={key === -1}
              contentChildren={
                types?.length ? (
                  <ul className={st.types}>
                    {types.map(({ title }, index) => (
                      <li
                        key={index}
                        onClick={() => setType({ ...type, [key]: index })}
                      >
                        {title}
                      </li>
                    ))}
                  </ul>
                ) : null
              }
            />
          </Fragment>
        )
      )}
      {Boolean(
        (enterprise.plantTitle && plants.length) ||
          (enterprise.workTitle &&
            (enterprise.stages?.length ||
              enterprise.works?.length ||
              enterprise.videos?.length))
      ) && (
        <section className={st.plantsWork} id="plantas">
          <div className={st.container}>
            {Boolean(enterprise.plantTitle && plants.length) && (
              <>
                <h3>{enterprise.plantTitle}</h3>
                <Tabs tabs={plants.map(({ name }) => name)}>
                  {plants.map(({ images, name }, key) => (
                    <div key={key}>
                      <Slider
                        className="slider"
                        renderArrow={(props, type) => (
                          <button
                            {...props}
                            className={cn(
                              'arrow',
                              type === ArrowType.Prev && 'prev'
                            )}
                          ></button>
                        )}
                      >
                        {images.map(({ urls, legend, title }, index) => (
                          <div key={index} className={st.plant}>
                            <div className={st.image}>
                              <img
                                src={urls.large}
                                width="580"
                                height="250"
                                onClick={() =>
                                  Zoom(
                                    index,
                                    images.map(({ urls }) => ({ urls }))
                                  )
                                }
                              />
                              <span>
                                Clique na planta para ampliar a imagem
                              </span>
                            </div>
                            {legend && title && (
                              <div className={st.numbers}>
                                <div>
                                  <strong>{name}</strong>
                                  <span className={st.area}>{legend}</span>
                                  <span className={st.parking}>{title}</span>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </Slider>
                    </div>
                  ))}
                </Tabs>
                <div className={st.btnWrapper}>
                  {enterprise.formTitle && (
                    <Button
                      onClick={() =>
                        scroller.scrollTo('saiba-mais', {
                          smooth: true,
                          duration: 500,
                        })
                      }
                    >
                      Solicite informações
                    </Button>
                  )}
                </div>
              </>
            )}
            {location.pathname === '/empreendimento/onne-home-residence/' && (
              <div className={st.commercialRoom}>
                <section className={st.content}>
                  <h2>
                    Alto acabamento, todas com mezanino, copa e garagem. Espaço
                    amplo com 145m² de área privativa.
                    <br />
                    <br />
                    Excelente oportunidade para você implantar seu negócio.
                  </h2>
                  <p>Localização privilegiada.</p>
                  <p>
                    Próximo à 3ª avenida, Avenida Brasil e à 500m da praia
                    Central, Av. Atlântica.
                  </p>
                </section>
                <Slider
                  className={st.slider}
                  renderArrow={(props, type) => (
                    <button
                      {...props}
                      className={cn(
                        st.arrow,
                        type === ArrowType.Prev && st.prev
                      )}
                    ></button>
                  )}
                >
                  {specificVideo && (
                    <div key={specificVideo.url} className={st.video}>
                      <Video
                        thumbnail={`https://img.youtube.com/vi/${getVideoId(
                          specificVideo.url
                        )}/0.jpg`}
                        src={specificVideo.url}
                      />
                    </div>
                  )}
                </Slider>
              </div>
            )}
            {Boolean(
              enterprise.workTitle &&
                (enterprise.stages?.length ||
                  enterprise.works?.length ||
                  enterprise.videos?.length)
            ) && (
              <>
                <h3>{enterprise.workTitle}</h3>
                {Boolean(enterprise.stages?.length) && (
                  <section>
                    <h4>Estágio de construção</h4>
                    <div className={st.stages}>
                      {enterprise.stages.map(({ name, value }, key) => (
                        <Fragment key={key}>
                          {Stage(name, parseInt(value))}
                        </Fragment>
                      ))}
                    </div>
                  </section>
                )}
                <div className={st.knowMore}>
                  <Button
                    onClick={() =>
                      scroller.scrollTo('saiba-mais', {
                        smooth: true,
                        duration: 500,
                        offset: large ? 0 : menuBarHeight(),
                      })
                    }
                  >
                    Saiba mais
                  </Button>
                </div>
                {Boolean(enterprise.works?.length) && (
                  <section>
                    <h4>Fotos</h4>
                    <div className={st.workImages}>
                      {enterprise.works.map(({ images }, key) => (
                        <div key={key}>
                          <Slider
                            className="slider"
                            slidesToShow={medium ? 3 : small ? 2 : 1}
                            renderArrow={(props, type) => (
                              <button
                                {...props}
                                className={cn(
                                  'arrow',
                                  type === ArrowType.Prev && 'prev'
                                )}
                              ></button>
                            )}
                          >
                            {images.map(({ urls }, index) => (
                              <img
                                key={index}
                                src={urls.large}
                                width="420"
                                height="305"
                                onClick={() => Zoom(index, images)}
                              />
                            ))}
                          </Slider>
                        </div>
                      ))}
                    </div>
                  </section>
                )}
                {Boolean(enterprise.videos?.length) && (
                  <section>
                    <h4>Vídeos</h4>
                    <div className={st.workImages}>
                      <Slider
                        className="slider"
                        slidesToShow={medium ? 3 : small ? 2 : 1}
                        renderArrow={(props, type) => (
                          <button
                            {...props}
                            className={cn(
                              'arrow',
                              type === ArrowType.Prev && 'prev'
                            )}
                          ></button>
                        )}
                      >
                        {enterprise.videos.map(({ url }, key) => (
                          <div key={key} className={st.video}>
                            <Video
                              thumbnail={`https://img.youtube.com/vi/${getVideoId(
                                url
                              )}/0.jpg`}
                              src={url}
                            />
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </section>
                )}
                {enterprise.workContent && (
                  <p className={st.legal}>{nl2br(enterprise.workContent)}</p>
                )}
              </>
            )}
          </div>
        </section>
      )}
      {Boolean(enterprise.latitude && enterprise.longitude) && (
        <MosaicSection
          title={enterprise.city}
          // description={`${enterprise.address} - ${enterprise.neighborhood}\n${enterprise.city} - ${enterprise.state}`}
          innerDescription={`${enterprise.address} - <strong>${enterprise.neighborhood}</strong>,\n${enterprise.city} - ${enterprise.state}`}
          buttonLabel="Traçar uma rota"
          url={`https://www.google.com/maps?saddr=My+Location&daddr=${enterprise.latitude},${enterprise.longitude}`}
          external
          id="localizacao"
        >
          <Map
            location={{
              lat: enterprise.latitude,
              lng: enterprise.longitude,
            }}
          />
        </MosaicSection>
      )}
      {enterprise.imageLocation && (
        <ImageSection src={enterprise.imageLocation} triangle={false} />
      )}
      {enterprise.formTitle && (
        <section className={st.form} id="saiba-mais">
          <div className={st1.container}>
            <div className={st1.forms}>
              <div>
                <div>
                  <h3>{enterprise.formTitle}</h3>
                  {enterprise.formContent && (
                    <p>{nl2br(enterprise.formContent)}</p>
                  )}
                </div>
                <Form
                  idPrefix="enterprise"
                  rdStation={`site-${slug}`}
                  button={<Button>Solicitar contato</Button>}
                  loadingButton={<Button>Carregando...</Button>}
                  hiddenInputs={{
                    legal_bases: [
                      {
                        category: 'communications',
                        type: 'consent',
                        status: 'granted',
                      },
                      {
                        category: 'data_processing',
                        type: 'consent',
                        status: 'granted',
                      },
                    ],
                  }}
                  inputs={[
                    {
                      label: 'Nome*',
                      name: 'name',
                    },
                    {
                      label: 'E-mail*',
                      name: 'email',
                      type: 'email',
                    },
                    {
                      label: 'WhatsApp*',
                      name: 'phone',
                      mask: 'phone',
                    },
                    {
                      label: 'Interesse do contato*',
                      name: 'subject',
                    },
                    {
                      label: 'Mensagem*',
                      name: 'message',
                      type: 'textarea',
                      className: st1.wide,
                    },
                    {
                      label: (
                        <>Declaro que li e aceito a Política de Privacidade</>
                      ),
                      name: 'privacy',
                      type: 'checkbox',
                      className: st1.privacy,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </section>
      )}
      <Footer />
      <WhatsApp location={location} />
      <Lightbox
        index={zoomIndex}
        setIndex={setZoomIndex}
        images={zoomImages}
        setImages={setZoomImages}
      />
    </>
  )
}

const EnterpriseRouter = (props: PageProps & { '*'?: string }) => {
  const slug =
    (props.pageContext as Context).enterprise?.slug || props['*'] || '-'

  return <EnterprisePage slug={slug} {...props} />
}

export default EnterpriseRouter
